<template>
  <v-list class="out-menu-white">
    <v-list-item
      class="out-menu-white__header"
      style="height: 35px; min-height: 35px"
    >
      <v-list-item-content class="py-0 pb-1">
        <v-list-item-title style="font-weight: 700">
          {{ title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item
      v-for="item in items"
      :key="item.label"
      style="height: 40px; min-height: 40px"
      link
    >
      <v-list-item-action>
        <v-checkbox v-model="item.value" color="#2b84eb" />
      </v-list-item-action>
      <v-list-item-content>{{ item.label }}</v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-btn
        height="34px"
        block
        class="custom-button custom-button--blue"
        @click="$emit('filter')"
      >
        Filter
      </v-btn>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "OuterCheckboxList",
  props: {
    title: {
      default: () => {},
      type: String,
    },
    items: {
      default: () => [],
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list.out-menu-white.theme--light {
  .v-list-item__action {
    margin-right: 18px;
  }

  .v-list-item__content {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
}
</style>
