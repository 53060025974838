<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col cols="12" class="d-flex align-baseline justify-space-between">
        <div class="title-text">
          Custom Fields
        </div>
      </v-col>
      <CustomInputItem
        v-for="(value, item) in custom_fields"
        :key="item"
        :custom-field-value="value"
        :custom-field-key="item"
      />
    </v-row>
  </v-container>
</template>

<script>
import CustomInputItem from "@/views/Contacts/components/DigitalPersona/CustomInputItem";

export default {
  name: "CustomInputBlock",
  components: {CustomInputItem},
  props: {
    custom_fields: {
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
};
</script>

<style lang="scss">
</style>
