<template>
  <div class="actions-panel">
    <v-expansion-panels flat class="elevation-0" multiple accordion :value="[0]">
      <v-expansion-panel
        v-for="(year) in contact_actions_by_years"
        :key="year.yearNo"
        class="elevation-0"
      >
        <v-expansion-panel-header class="actions-panel__header-outer">
          {{ year.yearNo }}
          <div slot="actions">
            <v-icon color="#66788e" size="13" class="ml-1">
              mdi-chevron-down
            </v-icon>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-expansion-panels
            v-for="(action, idx) in year.data"
            :key="idx"
            flat
            accordion
          >
            <v-expansion-panel
              v-if="visible_creative(action)"
              style="border-bottom: 1px solid #dee7ee"
            >
              <v-expansion-panel-header
                class="actions-panel__header-inner d-flex align-start"
              >
                <div class="d-flex flex-column">
                  <div class="d-flex align-center mb-3">
                    <CampaignTypeIcon :type="get_icon_name(action)" />
                    <div class="campaign-name ml-3">
                      <p class="creative-name" :title="action.creative || 'Unnamed'">
                        {{ action.creative || 'Unnamed' }}
                      </p>
                    </div>
                    <v-spacer />
                    <div class="campaign-date ml-1">
                      {{ getDateWithTime(action.action_date) }}
                    </div>
                  </div>
                  <div class="d-flex mb-2">
                    <div class="mr-4">
                      <span class="point-title">Action:</span>
                      &nbsp;
                      <span class="point-text">
                        {{ action.action_code_description }}
                      </span>
                    </div>
                    <div
                      v-if="action.campaign_id"
                      class="mr-4"
                    >
                      <span class="point-title">Campaign:</span>
                      &nbsp;
                      <span class="point-text">
                        {{ action.campaign_name }}
                      </span>
                    </div>
                  </div>
                </div>
                <div slot="actions">
                  <v-icon color="#66788e" size="18" class="ml-1">
                    mdi-chevron-right
                  </v-icon>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="actions-panel__content">
                  <div v-if="action.contact_method_display">
                    <span class="point-title">Method:</span>
                    &nbsp;
                    <span class="point-text">{{ action.contact_method_display }}</span>
                  </div>
                  <div>
                    <span class="point-title">Date:</span>
                    &nbsp;
                    <span class="point-text">{{
                      getDateWithDayNameAndTime(action.action_date)
                    }}</span>
                  </div>
                  <div
                    v-if="['email','banner','directMail'].includes(action.type)"
                    class="d-flex justify-center align-center mt-4"
                  >
                    <v-icon size="17" class="mr-1">
                      $eye
                    </v-icon>
                    <div
                      class="simple-text-button button--blue"
                      @click="$emit('preview-creative', action)"
                    >
                      Preview Creative
                    </div>
                  </div>
                  <div
                    v-if="['web'].includes(action.type)"
                    class="d-flex justify-center align-center mt-4"
                  >
                    <v-icon size="17" class="mr-1">
                      $eye
                    </v-icon>
                    <div
                      class="simple-text-button button--blue"
                      @click="viewActivity(action)"
                    >
                      View Activity
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-dialog v-model="activityDialogOpened" scrollable max-width="1000px">
      <ViewActivityDialog
        :web-tracking-action-id="actionId"
        @dismiss="activityDialogOpened = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import CampaignTypeIcon from "@/views/Contacts/components/DigitalPersona/CampaignTypeIcon";
import ViewActivityDialog from "@/views/Contacts/components/DigitalPersona/ViewActivityDialog";
import datesMixin from "@/utils/datesMixin.js";
import dayjs from "dayjs";

export default {
  name: "ActionsPanel",
  components: {
    CampaignTypeIcon,
    ViewActivityDialog
  },
  mixins: [datesMixin],
  props: {
    criteria_filters: {
      default: () => {},
    },
    contact_actions: {
      default: () => [],
    },
  },
  data() {
    return {
      actionId: null,
      activityDialogOpened: false,
    };
  },
  computed: {
    contact_actions_by_years() {
      if (!this.contact_actions || !this.contact_actions.length) return []

      let actions = {}
      this.contact_actions.forEach(item => {
        const monthYear = item.action_date.slice(0, 14)
        const nameMonthYear = this.getFullMonthYear(monthYear)
        if (!actions[nameMonthYear]) {
          actions[nameMonthYear] = []
        }

        actions[nameMonthYear].push(item)
      })

      const sortedArr = Object.entries(actions).sort(([k1, ],[k2, ]) => {
        return dayjs(k1).isBefore(dayjs(k2));
      })

      const sortedActions = []
      for(const arr of sortedArr){
        sortedActions.push({
          yearNo: arr[0],
          data: arr[1]
        })
      }

      return sortedActions
    },
  },
  methods: {
    viewActivity(action) {
      this.actionId = action.action_id;
      this.activityDialogOpened = true;
    },
    get_icon_name(action) {
      let sourcePrefix = "source|";
      if (action.source_name !== "Pulse Health" && action.source_image !== null){
          return sourcePrefix.concat(action.source_image);
      }

      const smsActions = ['Outgoing SMS', 'Incoming SMS', 'SMS Message Failed', 'SMS Opt Out']
      const actions = ['banner', 'phone', 'alert', 'web', 'form', 'event', 'prescription']
      if (!action.type) return 'sms'
      if (action.type.includes('mail')) {
        return 'email'
      } else if (smsActions.includes(action.action_code)) {
        return 'sms'
      } else if (action.action_code === 78 || action.action_code === 'video_view') {
        return 'video'
      } else if (actions.includes(action.type)) {
        return action.type
      }
    },
    visible_creative(campaign){
      if(!this.criteria_filters) {
        return true;
      }

      const filtered_criteria = this.criteria_filters.filter(item => !item.value);
      const disabled_criteria = [];

      filtered_criteria.forEach(item => {
        if (item.label === 'Banners') {
          return disabled_criteria.push('banner');
        }
        if (item.label === 'Direct Mail') {
          return disabled_criteria.push('direct_mail');
        }
        if (item.label === 'Alert') {
          return disabled_criteria.push('alert');
        }

        disabled_criteria.push(item.label.toLowerCase().trim());
      });

      if (!disabled_criteria.length) {
        return true;
      }

      return !disabled_criteria.includes(campaign.type?.toLowerCase())
    },
  }
};
</script>

<style lang="scss" scoped>
.actions-panel {
  &__header-outer {
    background: #f7f7f7;
    border-top: solid 1px #dee7ee;
    border-bottom: solid 1px #dee7ee;
    padding-left: 18px;
    padding-right: 18px;
    height: 45px;
    min-height: 45px;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    color: #66788e;

    .v-expansion-panel-header__icon {
      margin: 0 !important;
    }
  }
  &__header-inner {
    padding: 16px 18px 12px 18px;
    min-height: 45px;
    border: none;

    .campaign-name {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      line-height: normal;
      color: #241c15;
    }
    .creative-name {
      text-overflow: ellipsis;
      max-width: 140px;
      clear: both;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: 0;
    }
    .campaign-date {
      font-family: "Open Sans", sans-serif;
      line-height: normal;
      font-size: 12px;
      text-align: right;
      color: #66788e;
    }
  }
  &__content {
    padding: 15px 0;
    margin: 0 18px;
    border-top: 1px solid #dee7ee;
  }

  .point-title {
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    line-height: normal;
    font-weight: 600;
    color: #241c15;
  }
  .point-text {
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-weight: normal;
    line-height: normal;
    color: #66788e;
  }
}

::v-deep {
  .v-item-group.theme--light.v-expansion-panels {
    border-radius: 0;
  }
  .v-expansion-panel-content__wrap {
    padding: 0;
  }
  // .v-expansion-panel-header__icon {
  //   margin: 0;

  // }
  .actions-panel__header-outer .v-expansion-panel-header__icon {
    margin: 0;
  }
  .actions-panel__header-inner .v-expansion-panel-header__icon {
    margin-top: 6px;
  }

  .v-expansion-panel--active
    > .v-expansion-panel-header--active.actions-panel__header-inner
    .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate)
    .v-icon {
    transform: rotate(90deg);
    color: #2b84eb !important;
  }
}
</style>
