import { render, staticRenderFns } from "./DigitalFootprint.vue?vue&type=template&id=450bd521"
import script from "./DigitalFootprint.vue?vue&type=script&lang=js"
export * from "./DigitalFootprint.vue?vue&type=script&lang=js"
import style0 from "./DigitalFootprint.vue?vue&type=style&index=0&id=450bd521&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VContainer,VDialog,VIcon,VMenu})
