<template>
  <div class="date-picker-container">
    <v-date-picker
      :min="calendar_info.startDate"
      :max="calendar_info.endDate"
      color="#2b84eb"
      multiple
      :value="calendar_info.days"
      :show-current="false"
      :width="$vuetify.breakpoint.xsOnly ? null : width"
      :full-width="$vuetify.breakpoint.xsOnly"
      disabled
      no-title
    />

    <div class="d-flex justify-center mt-1">
      <v-btn
        width="20"
        height="24"
        class="mr-5"
        @click="clickPrevMonth"
      >
        <v-icon size="15">
          mdi-arrow-left
        </v-icon>
      </v-btn>
      <v-btn
        width="20"
        height="24"
        :disabled="!canGoToNextMonth"
        @click="clickNextMonth"
      >
        <v-icon size="15">
          mdi-arrow-right
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "Calendar",
  props: {
    calendar_info: {
      default: () => {},
    },
    currentMonth: {
      type: Date,
      default: () => new Date(),
    },
    width: {
      type: String,
      default: "190",
    }
  },
  computed: {
    canGoToNextMonth() {
      const now = dayjs().set('date', 1);
      const date = dayjs(this.currentMonth).add(1, 'month').set('date', 1);

      // if the date + 1 month is before now => ok to process
      // otherwise stop (even now is Dec 1 and date is Dec 1)
      return date.isBefore(now);
    },
  },
  methods: {
    clickPrevMonth() {
      this.$emit('prev-month');
    },
    clickNextMonth() {
      if (!this.canGoToNextMonth) {
        return;
      }

      this.$emit('next-month');
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .v-date-picker-table {
    height: auto !important;
  }

  .date-picker-container {
    .v-picker {
      .v-picker__body {
        .v-date-picker-header {
          display: none;
        }
        .v-date-picker-table {
          padding: 0 4px;

          table {
            thead {
              tr {
                th {
                  font-family: "Open Sans", sans-serif;
                  font-size: 10px;
                  color: #241c15;
                  width: 20px;
                }
              }
            }
            tbody {
              tr {
                td {
                  width: 20px;
                  .v-btn {
                    width: 100%;
                    .v-btn__content {
                      font-family: "Open Sans", sans-serif;
                      font-size: 10px;
                      color: #a5a9b8;
                      font-weight: normal;
                      font-stretch: normal;
                      font-style: normal;
                      line-height: normal;
                      letter-spacing: normal;
                    }
                  }
                  .v-btn.v-btn--active {
                    background-color: #2b84eb !important;
                    height: 19px;
                    width: 19px;
                    border-radius: 9.5px;
                    // position: absolute;
                    // top: 7px;
                    // left: 4px;
                    .v-btn__content {
                      color: white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
