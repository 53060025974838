<template>
  <v-card class="custom-fields-popup-card">
    <v-card-title class="px-6 pt-4 pb-4">
      Select Contact Segments
    </v-card-title>
    <v-card-text class="px-0 pb-2">
      <v-text-field
        v-model="search"
        class="mb-2 pl-6 pr-5"
        prepend-inner-icon="mdi-magnify"
        clearable
        dense
        outlined
        placeholder="Search for segment"
        hide-details
      />

      <v-divider />
      <v-list-item-group>
        <div v-for="(value, item) in searchResults" :key="item">
          <v-list-item class="ma-2">
            <v-list-item-content>
              <v-list-item-title>
                <div class="segment-block__name pr-3">
                  {{ value.name }} {{ value.isArchived ? " [Archived]": "" }}
                </div>
              </v-list-item-title>
              <v-list-item-subtitle>
                <div class="segment-block__created" :title="getDateWithDayNameAndTime(value.createdAt)">
                  {{ `Created ${getDateWithMonthName(value.createdAt)}` }}
                </div>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                # of Contacts: {{ value.contactCount }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-checkbox :value="is_contact_in_segment(value)" @change="change_contact_in_segment($event,value)" />
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </div>
      </v-list-item-group>


      <v-card-actions
        class="divider-with-shadow d-flex justify-end px-6 pb-0 mt-8"
      >
        <div
          class="simple-text-button button--gray mx-8 d-flex align-center"
          @click="$emit('dismiss')"
        >
          Close
        </div>

        <v-btn
          height="34px"
          class="elevation-0 custom-button custom-button--blue"
          width="150px"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "EditSegmentsDialog",
  components: {},
  mixins: [datesMixin],
  props: {
    contact_segments: {
      default: () => [],
    },
    contactId: {
      default: () => {},
    }
  },

  data(){
    return {
      all_segments:{},
      search:"",
      search_placeholder:"",
      checked_segments:{},
      segments_to_save: {},
      isSearch: false,
    }
  },
  computed: {
    searchResults() {
      if (this.search) {
        return this.all_segments.filter(item => {
          return item.name.toLowerCase().includes(this.search.toLowerCase());
        }) || [];
      }
      return this.all_segments;
    }
  },
  async created() {
    await this.get_segments();
    this.checked_segments = this.intersect(this.contact_segments, this.all_segments);
  },
  methods: {
    async get_segments() {
      const resp = await this.$rest.segment.get_collection({ignorePagination:true, sort:["name:asc"], isArchived: false});
      this.all_segments = resp.data.items.map(segment => ({
        ...segment,
        contactCount: segment.contactCount
      }));
    },
    intersect(a, b) {
      var res = {};
      if (a.length === 0 || b.length === 0) return {};
      for(var i=0;i<a.length;i++)
      {
          if(b.find(itm => itm.id === a[i].segmentId && !a[i].isExcluded))
            res[a[i].segmentId]=a[i].segmentId;
      }
      return res;
    },
    is_contact_in_segment(segment) {
      return segment.id in this.checked_segments;
    },
    change_contact_in_segment(e, segment) {
      this.segments_to_save[segment.id] = e;
    },
    save() {
      // get unchecked segment id
      const segmentActionObj = {};
      for(const segment of this.contact_segments){
        if(segment.segmentId in this.segments_to_save && !this.segments_to_save[segment.segmentId]){
          segmentActionObj[segment.segmentId] = false;

          // remove segment that already handle
          delete this.segments_to_save[segment.segmentId];
        }
      }

      // get new segments that is checked
      for(const id in this.segments_to_save){
        if(this.contact_segments.findIndex((field) => field.segmentId === id) < 0){
          segmentActionObj[id] = true;
          delete this.segments_to_save[id];
        }
      }
      this.$emit('save_segments', segmentActionObj)
    }
  }
};
</script>
