<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col cols="12" class="d-flex align-baseline justify-space-between">
        <div class="title-text">
          {{ `Segments (${contact_segment_count})` }}
        </div>
        <div
          v-if="$store.getters['user/hasWritePermission']"
          class="simple-text-button button--blue"
          @click="edit_segments_dialog_key++; edit_segments_dialog_opened = true"
          v-text="contact_segment_count ? 'Edit Segments' : 'Add to Segments'"
        />
      </v-col>
    </v-row>
    <v-row class="ml-3 pr-6 pr-sm-2">
      <v-col
        v-for="segment in contact_segments"
        :key="segment.id"
        cols="12"
        sm="4"
        class="px-0 px-sm-1"
      >
        <Segment
          :contact_segment="segment"
          @action="handleAction($event)"
        />
      </v-col>
    </v-row>

    <v-dialog
      v-model="edit_segments_dialog_opened"
      scrollable
      max-width="400px"
    >
      <EditSegmentsDialog
        :key="edit_segments_dialog_key"
        :contact_segments="contact_segments"
        :contact-id="contactId"
        @save_segments="save_segments"
        @dismiss="edit_segments_dialog_opened = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import EditSegmentsDialog from "@/views/Contacts/components/DigitalPersona/EditSegmentsDialog";
import Segment from "@/views/Contacts/components/DigitalPersona/Segment";

export default {
  name: "Segments",
  components: { Segment, EditSegmentsDialog },
  props: {
    contactId: {
      default: () => {},
    },
  },
  data() {
    return {
      edit_segments_dialog_opened: false,
      segments: {},
      contact_segments: {},
      contact_segment_count: 0,
      edit_segments_dialog_key: 0
    };
  },
  async created() {
    await this.get_segments();
  },
  methods: {
    async get_segments() {
      const resp = await this.$rest.contact.get_contact_segments(
        this.contactId,
        {ignorePagination: true, isArchived: false}
      );
      this.contact_segment_count = resp.data.totalCount;
      this.contact_segments =  resp.data.items;
    },
   
    async save_segments(segments){
      for (const [key, value] of Object.entries(segments)) {
        if (value === true)
          await this.$rest.segment.add_to_segment(key, {contact: +this.contactId, isManualInsert: 1, isExcluded:false})
        else await this.$rest.segment.remove_from_segment(key, +this.contactId)
      }
      await this.get_segments();
      await this.refresh_active_segments();
      this.edit_segments_dialog_opened = false;
    },
    async handleAction(payload) {
      if (payload.item.value === "remove-from-segment") {
        await this.$rest.segment.remove_from_segment(payload.contact_segment.segmentId, +this.contactId);
        await this.get_segments();
        await this.refresh_active_segments();
      } else if (payload.item.value === "view-all-contacts") {
        // show all contact with this segment
        await this.$router.push({
          name: 'ContactsSearch',
          query: {
            segmentId: payload.contact_segment.segmentId,
          },
        });
      }
    },
    async refresh_active_segments() {
      // after deleted/added, segments_active should get updated also
      await this.get_segments();
    }
  },
};
</script>

<style lang="scss" scoped>
.title-text {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  color: #241c15;
}
</style>
