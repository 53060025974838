<template>
  <div class="segment-block d-flex align-center justify-space-between px-3 py-4 pl-md-2 pr-md-0 px-lg-3">
    <div class="mr-3 mr-md-0 mr-lg-3">
      <div class="d-flex align-start mb-1">
        <div class="segment-block__name pr-3">
          {{ contact_segment.segment.name }}
        </div>
        <v-menu nudge-top="-5px" :offset-y="true">
          <template #activator="{ on, attrs }">
            <v-icon
              v-if="contact_segment.segment.type === '2'"
              v-bind="attrs"
              size="19px"
              color="#2b84eb"
              v-on="on"
            >
              mdi-alpha-d-circle
            </v-icon>
            <v-icon
              v-if="contact_segment.segment.type === '1'"
              v-bind="attrs"
              size="19px"
              color="#1f9f43"
              v-on="on"
            >
              mdi-alpha-s-circle
            </v-icon>
            <!-- </div> -->
          </template>
          <SegmentTypeOuterList
            :icon-name="contact_segment.segment.type === '1' ? 'mdi-alpha-s-circle' : 'mdi-alpha-d-circle'"
            :icon-color="contact_segment.segment.type === '1' ? '#1f9f43' : '#2b84eb'"
            :title="title"
            :description="segmentTypeDescription"
          />
        </v-menu>
      </div>
      <div class="segment-block__created" :title="getDateWithDayNameAndTime(contact_segment.segment.createdAt)">
        {{ `Created ${getDateWithMonthName(contact_segment.segment.createdAt)}` }}
      </div>
    </div>
    <div>
      <v-menu nudge-top="-5px" nudge-left="125px" :offset-y="true">
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon color="#66788e">
              mdi-dots-vertical
            </v-icon>
          </v-btn>
        </template>
        <OuterList
          title="Segment Options"
          :items="listItems"
          @click="(item) => $emit('action', {item, contact_segment})"
        />
      </v-menu>
    </div>
  </div>
</template>

<script>
import OuterList from "@/sharedComponents/OuterList";
import datesMixin from "@/utils/datesMixin.js";
import SegmentTypeOuterList from "@/views/Segments/components/SegmentTypeOuterList";

export default {
  name: "Segment",
  components: { SegmentTypeOuterList, OuterList },
  mixins: [datesMixin],
  props: {
    contact_segment: {
      default: () => {},
    },
  },
  data() {
    return {};
  },
  computed: {
    segmentTypeDescription() {
      const staticType =
        'This segment can not change in size when new contacts are added or removed. To enable this feature, change to a Dynamic segment';
      const dynamicType =
        'This segment can change in size when new contacts are added or removed. To disable this, change to a Static segment';
      return this.contact_segment.type === '1'
        ? staticType
        : dynamicType;
    },
    title: {
      get() {
        return this.contact_segment.type === '1'
            ? 'Static segment'
            : 'Dynamic segment';
      },
    },
    listItems() {
      const items = [];
      const canWrite = this.$store.getters['user/hasWritePermission'];

      items.push({
        label: "View All Contacts",
        icon: "mdi-account-multiple-outline",
        value: "view-all-contacts",
      });

      if (canWrite) {
        items.push({
          label: "Remove From Segment",
          icon: "mdi-delete-outline",
          value: "remove-from-segment",
        });
      }

      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
.segment-block {
  border-radius: 4px;
  border: solid 1px #dee7ee;

  &__name {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
  &__icon {
    margin-top: 2px;
  }

  &__created {
    font-family: "Open Sans", sans-serif;
    font-size: 11px;
    font-weight: normal;
    line-height: normal;
    color: #66788e;
  }
}
</style>
