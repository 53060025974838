<template>
  <v-progress-circular
    color="#2b84eb"
    :value="value"
    :rotate="-90"
    :width="2.5"
    :size="20"
  />
</template>

<script>
export default {
  name: "ProgressCircle",
  props: {
    value: {
      default: () => 0,
    },
  },
};
</script>
