<template>
  <div class="digital-persona d-flex blur contact-profile-block">
    <div class="main-block flex-grow-1">
      <v-container fluid class="pa-0">
        <div class="digital-persona__header-block--blue" />
      </v-container>
      <v-container class="px-5 px-sm-10 px-md-4 px-lg-10 pt-0">
        <Header :contact_details="contact_details">
          <div
            v-for="item in actionPanels"
            :key="item.text"
            class="action-panel-buttons__icon-container ml-4"
          >
            <v-icon
              class="action-panel-buttons__icon"
              color="#a5a9b8"
              size="18"
              @click="handleProfileAction(item)"
            >
              {{ item.icon }}
            </v-icon>
            <div class="action-panel-buttons__icon-text icon-text">
              {{ item.text }}
            </div>
          </div>
        </Header>

        <GeneralInfo :contact_details="contact_details" :contact_statistics="contact_statistics" />

        <v-row v-if="$vuetify.breakpoint.smAndDown">
          <v-col cols="12" sm="auto" class="pb-0">
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              height="34px"
              :width="$vuetify.breakpoint.smOnly ? '150px' : null"
              class="elevation-0 custom-button custom-button--gray"
              @click="digital_footprint_view_opened = true"
            >
              Digital Footprint
            </v-btn>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.xsOnly" cols="12">
            <v-btn
              :block="$vuetify.breakpoint.xsOnly"
              height="34px"
              class="elevation-0 custom-button custom-button--gray"
            >
              Actions
            </v-btn>
          </v-col>
        </v-row>

        <v-divider v-if="$vuetify.breakpoint.xsOnly" class="my-2" />

        <EngagementMetrics
          :contact_details="contact_details"
          :contact_statistics="contact_statistics"
          :touchpoints="touchpoints"
          :calendar-month="currentDate"
          @prev-month="getActionByMonth(true)"
          @next-month="getActionByMonth(false)"
        />

        <v-divider
          v-show="Object.keys(contact_details.customFields || {}).length > 0"
          class="mt-2 mb-6"
        />

        <CustomInputBlock
          v-show="Object.keys(contact_details.customFields || {}).length > 0"
          :custom_fields="contact_details.customFields"
        />

        <v-divider class="mt-6 mb-6" />

        <ContactInfo :contact_info="{emails: contact_emails, phones: contact_phones, locations: contact_locations}" />

        <v-divider
          v-if="contactProfileDataSections.length > 0"
          class="mt-3 mb-6"
        />
        <template
          v-for="(component, key) in contactProfileDataSections"
        >
          <component
            :is="component"
            :key="'contact-info-data-section-' + key"
            :contact="contact_details"
          />
        </template>

        <v-divider class="mt-3 mb-6" />
        <Segments :contact-id="id" />
      </v-container>
    </div>
    <div v-if="$vuetify.breakpoint.mdAndUp" class="digital-persona__aside">
      <DigitalFootprint
        v-if="contact_actions"
        :id="id"
        :contact_details="contact_details"
        :parent-contact-actions="contact_actions"
        :is-use-data-from-parent="true"
      />
    </div>

    <v-dialog
      v-if="$vuetify.breakpoint.smAndDown"
      v-model="digital_footprint_view_opened"
      transition="dialog-bottom-transition"
      width="100%"
      content-class="footprint-dialog"
      persistent
      no-click-animation
      hide-overlay
    >
      <DigitalFootprint
        v-if="contact_actions"
        :id="id"
        :contact_details="contact_details"
        :parent-contact-actions="contact_actions"
        :is-use-data-from-parent="true"
        @dismiss="digital_footprint_view_opened = false"
      />
    </v-dialog>

    <v-dialog
      v-model="isShowingConfirmExport"
      scrollable
      max-width="400px"
    >
      <DeleteConfirmationDialog
        v-if="isShowingConfirmExport"
        header="Export Contact"
        subheader="Do you really want to export this contact?"
        action_button_name="Confirm"
        @delete="exportContact()"
        @dismiss="isShowingConfirmExport = false;"
      />
    </v-dialog>

    <v-dialog
      v-model="isShowingConfirmDelete"
      scrollable
      max-width="400px"
    >
      <DeleteConfirmationDialog
        v-if="isShowingConfirmDelete"
        header="Delete Contact"
        subheader="Do you really want to delete this contact?"
        action_button_name="Confirm"
        @delete="deleteContact()"
        @dismiss="isShowingConfirmDelete = false;"
      />
    </v-dialog>
  </div>
</template>

<script>
import Segments from "@/views/Contacts/components/DigitalPersona/Segments";
import GeneralInfo from "@/views/Contacts/components/DigitalPersona/GeneralInfo";
import ContactInfo from "@/views/Contacts/components/DigitalPersona/ContactInfo";
import EngagementMetrics from "@/views/Contacts/components/DigitalPersona/EngagementMetrics";
import CustomInputBlock from "@/views/Contacts/components/DigitalPersona/CustomInputBlock";
import Header from "@/views/Contacts/components/DigitalPersona/Header";
import DigitalFootprint from "@/views/Contacts/components/DigitalPersona/DigitalFootprint";
import dayjs from "dayjs";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";

export default {
  name: "ContactProfile",
  metaInfo: {
    title: 'Contact Profile'
  },
  components: {
    DeleteConfirmationDialog,
    Header,
    Segments,
    GeneralInfo,
    EngagementMetrics,
    CustomInputBlock,
    ContactInfo,
    DigitalFootprint,
  },
  props: {
    id: {
      default: () => false,
    },
  },
  data() {
    return {
      digital_footprint_view_opened: false,
      phones_expanded: false,
      emails_expanded: false,
      contact_details: {},
      contact_actions: null,
      contact_phones:[],
      contact_locations: [],
      contact_emails: [],
      contact_statistics: [],
      currentDate: new Date(),
      isShowingConfirmExport: false,
      isShowingConfirmDelete: false,
    };
  },
  computed: {
    contactProfileDataSections() {
      return this.$store.getters['user/pluginComponents']('contactInfoDataSections');
    },
    touchpoints() {
      return this.contact_actions?.totalCount ?? 0
    },
    actionPanels() {
      const items = [];
      const canWrite = this.$store.getters['user/hasWritePermission'];

      if (canWrite) {
        items.push({
          icon: "mdi-pencil-outline",
          text: "Edit contact",
          route: "EditContact",
        });
      }

      items.push({
        icon: "mdi-magnify",
        text: "Find similar",
        route: "FindSimilar",
      });

      items.push({
        icon: "mdi-export",
        text: "Export contact",
        action: "confirmExport",
      });

      if (canWrite) {
        items.push({
          icon: "mdi-delete-outline",
          text: "Delete contact",
          action: 'confirmDelete',
        });
      }

      return items;
    },
  },
  async created() {
    await this.get_contact_details(this.id);
    await this.get_contact_actions(this.id);
    await this.get_contact_statistics(this.id);

    this.contact_phones = this.contact_details.contactPhones.sort((a, b) => {
      return (b.isPhoneDefault + b.isSmsDefault) - (a.isPhoneDefault + a.isSmsDefault);
    });
    this.contact_locations =  this.contact_details.contactLocations.sort((a, b) => {
      return b.isDefault - a.isDefault;
    });
    this.contact_emails =  this.contact_details.contactEmails.sort((a, b) => {
      return b.isDefault - a.isDefault;
    });
    document.getElementsByClassName('digital-persona')[0].classList.remove('blur');
  },
  methods: {
    async get_contact_details(id) {
      const groups = [
        'contact_email',
        'contact_email:plugins',
        'contact_location',
        'contact_phone',
        'source'
      ];

      if (this.$store.getters['user/activePlugins']?.includes("AddPluginRepManagement")) {
        groups.push('rep');
      }
      if (this.$store.getters['user/activePlugins']?.includes("AddPluginVeeva")) {
        groups.push('plugin_data');
      }

      this.contact_details = (
        await this.$rest.contact.get_contact(id, {groups})
      ).data;
    },
    async get_contact_statistics(id) {
      this.contact_statistics = (await this.$rest.contact.get_contact_statistics(id)).data;
    },
    async get_contact_actions(id) {
      this.contact_actions = (
        await this.$rest.contact.get_contact_actions(id, {
          hide_loader: false
        })
      ).data;
    },

    onDeleteField(item) {
      for (let i = 0; i < this.custom_fields.length; i++) {
        if (item.name === this.custom_fields[i].name) {
          this.custom_fields.splice(i, 1);
          break;
        }
      }
    },
    async getActionByMonth(isPrev) {
      const currentDate = dayjs(this.currentDate);
      const month = isPrev
        ? currentDate.subtract(1, 'month')
        : currentDate.add(1, 'month')

      const response = await this.$rest.contact.get_contact_action_dates(this.id, {
        startDate: month.set('date', 1).format('YYYY-MM-DD'),
        endDate: month.set('date', month.daysInMonth()).format('YYYY-MM-DD'),
      });

      this.currentDate = month.toDate();

      // no-content will be considered as empty array
      if (!response.data) {
        this.$set(this.contact_statistics, 'days', []);
        return;
      }

      this.$set(this.contact_statistics, 'days', response.data);
    },
    handleProfileAction(selectedAction) {
      if (selectedAction.route) {
        return this.$router.push({
          name: selectedAction.route,
          params: {
            id: this.contact_details.id,
          },
        });
      }

      if (selectedAction.action && typeof this[selectedAction.action] === 'function') {
        this[selectedAction.action]();
      }
    },
    confirmExport() {
      this.isShowingConfirmExport = true;
    },
    confirmDelete() {
      this.isShowingConfirmDelete = true;
    },
    async exportContact() {
      const accountId = this.$store.getters['user/account']?.accountId;
      if (!accountId) {
        this.$store.commit('snackbar/showMessage', {
          content: 'Cannot find user account',
          color: 'error',
        });
        return;
      }

      this.isShowingConfirmExport = false;

      await this.$rest.exports.post_resource({
        exportType: 'contact_history',
        exportData: {
          contactId: this.contact_details.id
        }
      });

      await this.$router.push({name: "ExportsView"});
    },
    async deleteContact() {
      this.isShowingConfirmDelete = false;

      await this.$rest.contact.delete_contact(this.contact_details.id);

      this.$store.commit('snackbar/showMessage', {
        content: 'Contact deleted successfully!',
        color: 'info',
      });

      this.$router.push({
        name: 'ContactsSearch',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.blur {
  filter: blur(4px);
}
::v-deep .digital-persona, .digital-persona {
  &__aside {
    min-width: 367px;
    max-width: 367px;
    width: 367px;
    border-left: solid 1px #dee7ee;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.31);
  }

  &__header-block--blue {
    height: 82px;
    width: 100%;
    background: #13699d;
  }
  &__avatar {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    box-sizing: content-box;
    border: solid 5px #ffffff;
    background: #fff;
    object-fit: cover;
    position: relative;
    top: -35px;
    margin-left: -5px;
  }
  &__name {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    line-height: normal;
    color: #241c15;
  }

  .action-panel-buttons {
    &__icon-container {
      position: relative;
    }
    &__icon:hover {
      color: #2b84eb !important;
      cursor: pointer;
    }
    &__icon:hover ~ .icon-text {
      display: block;
    }
    &__icon-text {
      width: 80px;
      margin-left: -31px;
      margin-top: 3px;
      position: absolute;
      font-family: "Open Sans", sans-serif;
      font-size: 12px;
      color: #2b84eb;
      text-align: center;
      display: none;
    }
  }
}

.footprint-dialog.v-dialog {
  margin: 0;
  border-radius: 0;
  height: 100%;
  margin-top: 78px;
  box-shadow: none;
  bottom: 0;
}
</style>
