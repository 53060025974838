<template>
  <v-list class="segment-out-menu-white">
    <v-list-item
      class="segment-out-menu-white__header"
      style="height: 35px; min-height: 35px"
      link
    >
      <v-list-item-content class="py-0 pb-1">
        <v-list-item-title style="font-weight: 700" class="d-flex align-center">
          <v-icon size="20px" :color="iconColor" class="pr-1">
            {{ iconName }}
          </v-icon>
          <div>{{ title }}</div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider />
    <v-list-item style="min-height: 40px">
      <v-list-item-content class="segment-out-menu-white__description">
        <div>{{ description }}</div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "SegmentTypeOuterList",
  props: {
    title: {
      default: () => {},
      type: String,
    },
    description: {
      default: () => {},
      type: String,
    },
    iconName: {
      default: () => {},
      type: String,
    },
    iconColor: {
      default: () => {},
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list.segment-out-menu-white.theme--light {
  padding-top: 5px !important;
  background-color: white !important;
  width: 164px;

  .segment-out-menu-white__header {
    padding: 0 8px;
  }

  .v-list-item {
    &__title {
      font-family: "Open Sans", sans-serif;
      font-size: 13px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #241c15;
    }

    &__content {
      font-size: 13px;
      color: #66788e;
      font-family: "Open Sans", sans-serif;

      div {
        line-height: normal;
      }
    }
  }

  .v-divider.theme--light {
    background: #dee7ee;
  }
}
</style>
