<template>
  <v-container class="pa-0" fluid>
    <v-row>
      <v-col cols="12" sm="auto" class="py-0">
        <div v-if="contact_details.source && contact_details.source.name" class="d-flex justify-space-between justify-sm-start pt-sm-1 pb-2">
          <div class="point-text pr-2">
            Source:
          </div>
          <div class="gray-text">
            {{ contact_details.source.name }}
          </div>
        </div>
        <div class="d-flex justify-space-between justify-sm-start pt-sm-1 pb-2">
          <div class="point-text pr-2">
            Lead Segment:
          </div>
          <div class="gray-text">
            {{ leadScoreSegmentName }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="auto" class="py-0">
        <div class="d-flex justify-space-between justify-sm-start pt-sm-1 pb-2">
          <div class="point-text pr-2">
            Created Date:
          </div>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <div
                class="gray-text"
                v-bind="attrs"
                v-on="on"
              >
                {{ getDate(contact_details.createdAt) }}
              </div>
            </template>
            <span>{{ getDateWithDayNameAndTime(contact_details.createdAt) }}</span>
          </v-tooltip>
        </div>
        <div v-if="contact_details.npi" class="d-flex justify-space-between justify-sm-start pt-sm-1 pb-2">
          <div class="point-text pr-2">
            NPI:
          </div>
          <div class="gray-text">
            {{ contact_details.npi }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";
export default {
  name: "GeneralInfo",
  mixins: [datesMixin],
  props: {
    contact_details: {
      default: () => {},
    },
    contact_statistics: {
      default: () => {},
    },
  },
  computed: {
    leadScoreSegmentName() {
      return this.contact_statistics?.contactStatistics?.leadScoreSegmentName ?? 'n/a';
    }
  },
};
</script>
