<template>
  <v-col
    cols="auto"
    class="d-flex justify-space-between justify-sm-start align-center"
  >
    <div class="point-text pr-2">
      {{ customFieldLabel }}:
    </div>
    <div class="point-text point-text--regular">
      {{ parsedValue }}
    </div>
  </v-col>
</template>

<script>
export default {
  name: "CustomInputItem",
  props: {
    customFieldValue: {
      required: true,
    },
    customFieldKey: {
      required: true,
    },
  },
  computed: {
    customFields() {
      const customFields = this.$store.getters['user/account'].customFieldDefinition ?? [];
      return customFields.contact;
    },
    customFieldConfiguration() {
      return this.customFields?.find(item => item.fieldName === this.customFieldKey);
    },
    customFieldLabel() {
      return this.customFieldConfiguration?.name ?? this.customFieldKey;
    },
    customFieldDefaultValue() {
      if (this.customFieldConfiguration?.editType === "choice") {
        return this.getChoiceValueLabel(this.customFieldConfiguration.defaultValue);
      }

      return this.customFieldConfiguration?.defaultValue ?? 'n/a';
    },
    parsedValue() {
      if (this.customFieldValue === null || this.customFieldValue === undefined) {
        return this.customFieldDefaultValue;
      }

      if (this.customFieldConfiguration?.editType === "choice") {
        return this.getChoiceValueLabel(this.customFieldValue);
      } else if (typeof this.customFieldValue == 'string' || typeof this.customFieldValue == 'number') {
        return this.customFieldValue;
      } else if (this.customFieldValue instanceof Date) {
        return this.customFieldValue.toLocaleDateString();
      } else if (this.customFieldConfiguration?.type === "boolean") {
        return this.customFieldValue === true ? 'Yes' : 'No';
      } else {
        return this.customFieldValue.toString();
      }
    },
  },
  methods: {
    getChoiceValueLabel(choiceVal) {
      const defaultChoice = this.customFieldConfiguration.editChoices.find(
          item => item.value === choiceVal
      );

      if (!defaultChoice) {
        return choiceVal;
      }

      return defaultChoice.label;
    },
  },
}
</script>

<style scoped>

</style>
