<template>
  <v-card class="custom-fields-popup-card" height="500px">
    <v-card-title class="px-6 pt-4 pb-4">
      View Activity
    </v-card-title>
    <v-divider />

    <v-card-text class="pa-0 pb-2">
      <div>
        <v-simple-table>
          <thead>
            <tr>
              <th>Title</th>
              <th>Action Name</th>
              <th>Engagement Time</th>
              <th>Date Time</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in records" :key="index">
              <td class="ellipsis">
                <a
                  class="download-link"
                  target="_blank"
                  :href="item.uri"
                >
                  <span v-if="item.title">
                    {{ item.title }}
                  </span>
                  <span v-else>
                    {{ item.uri }}
                  </span>
                </a>
              </td>
              <td>
                {{ getActivityTypeName(item.type) }}
              </td>
              <td>
                {{ item.engagementTime }} seconds
              </td>
              <td>
                {{ getDateWithTime(item.activityDate) }}
              </td>
            </tr>
            <tr v-if="!records.length">
              <td colspan="5">
                No record found.
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>
    </v-card-text>

    <v-card-actions class="divider-with-shadow d-flex justify-end">
      <v-btn
        height="34px"
        class="elevation-0 custom-button custom-button--blue"
        width="150px"
        @click="$emit('dismiss')"
      >
        Dismiss
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "ViewActivityDialog",
  mixins: [datesMixin],
  props: {
    webTrackingActionId: {
      default: () => false
    }
  },
  data() {
    return {
      records: {},
    }
  },
  watch: {
    webTrackingActionId: {
      deep: true,
      handler(val) {
        this.updateRecord(val)
      }
    },
  },
  async created() {
    await this.updateRecord(this.webTrackingActionId)
  },
  methods: {
    async updateRecord(webTrackingActionId) {
      const resp = await this.$rest.actions.web({
        webTrackingActionId: webTrackingActionId,
        sort: ['createdAt:desc']
      });

      this.records = resp.data.items;
    },
    getActivityTypeName(type) {
      switch(type) {
        case "60":
          return 'Webpage Visit';
        case "63":
          return 'Media View';
        case "64":
          return 'Download file';
      }

      return 'Unknown'
    }
  },
};
</script>

<style lang="scss" scoped>
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
</style>
