<template>
  <v-container class="digital-footprint pa-0" fluid>
    <div
      v-if="$vuetify.breakpoint.smAndDown"
      class="button-block d-flex align-center justify-center"
    >
      <div
        class="simple-text-button button--blue mx-8 d-flex align-center"
        @click="$emit('dismiss')"
      >
        <v-icon color="#2b84eb" size="18" class="mr-1">
          mdi-chevron-left
        </v-icon>
        Return to Contact
      </div>
    </div>
    <div class="d-flex justify-space-between align-center mt-3 mx-4 mb-6">
      <div class="footprint-header">
        <div class="footprint-header__name mb-2">
          {{ fullName }}
        </div>
        <div class="footprint-header__title">
          Digital Footprint
        </div>
      </div>
      <div class="simple-text-button button--blue d-flex align-center">
        <v-menu
          v-model="activityFilterMenu"
          nudge-top="-5px"
          nudge-left="70px"
          :offset-y="true"
          :close-on-content-click="false"
        >
          <template #activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              All Activity
              <v-icon color="#2b84eb" size="15">
                mdi-chevron-down
              </v-icon>
            </div>
          </template>
          <OuterCheckboxList
            title="Filter By Activity"
            :items="contact_actions_type"
            @filter="activityFilterMenu = false; onFilterActivity()"
          />
        </v-menu>
      </div>
    </div>

    <ExpansionPanels
      :criteria_filters="tactics"
      :contact_actions="contact_actions"
      @preview-creative="(item) => on_preview_creative(item)"
    />

    <p
      v-if="!contact_actions.length"
      class="text-center px-4"
    >
      This contact has no action data available, yet. Add this contact to future campaigns and check back later!
    </p>

    <v-dialog v-model="creative_dialog_opened" scrollable max-width="1000px">
      <PreviewCreativeDialog
        :creative_content="creative_content"
        :creative="creative"
        @dismiss="creative_dialog_opened = false"
      />
    </v-dialog>
    <PaginationButtons
      v-if="contact_actions !== null && contact_actions.length > 0"
      :current-page-number="currentPageNumber"
      :current-page-size="currentPageSize"
      :count-total="totalItems"
      :page-sizes="[25, 50, 100]"
      @next-page="nextPage"
      @prev-page="prevPage"
      @change-page-size="changePageSize"
    />
  </v-container>
</template>

<script>
import OuterCheckboxList from "@/sharedComponents/OuterCheckboxList";
import ExpansionPanels from "@/views/Contacts/components/DigitalPersona/ExpansionPanels";
import PreviewCreativeDialog from "@/views/Contacts/components/DigitalPersona/PreviewCreativeDialog";
import datesMixin from "@/utils/datesMixin";
import PaginationButtons from "@/sharedComponents/pagination/PaginationButtons";

export default {
  name: "DigitalFootprint",
  components: {
    OuterCheckboxList,
    ExpansionPanels,
    PreviewCreativeDialog,
    PaginationButtons,
  },
  mixins: [datesMixin],
  props: {
    contact_details: {
      default: () => {},
    },
    id: {
      default: () => {},
    },
    parentContactActions: {
      type: Object,
      default: null,
    },
    isUseDataFromParent: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      activityFilterMenu: false,
      creative_dialog_opened: false,
      creative_content: null,
      creative: null,
      contact_actions: [],
      contact_actions_temp: {},
      contact_actions_type: [
        { label: "Email", value: false, code: "email" },
        { label: "Banner", value: false, code: "banner" },
        { label: "Event", value: false, code: "event" },
        { label: "Direct Mail", value: false, code: "directMail" },
        { label: "Web", value: false, code: "web" },
        { label: "Form", value: false, code: "form" },
        { label: "Phone", value: false, code: "phone" },
      ],
      tactics: [],
      currentPageNumber: 1,
      totalItems: 0,
      currentPageSize: 25,
    };
  },
  computed: {
    filterActionsType() {
      return this.contact_actions_type.filter(t => t.value).map(t => t.code)
    },
    fullName() {
      if (this.contact_details.contactType === 'HCP') {
        if (this.contact_details.lastName) {
          return `Dr ${this.contact_details.lastName}’s`;
        } else {
          return "(name not available)";
        }
      }

      if (!this.contact_details.firstName && !this.contact_details.lastName) {
        return "(name not available)";
      } else {
        return `${this.contact_details.firstName} ${this.contact_details.lastName}’s`;
      }
    },
  },
  watch: {
    parentContactActions(val) {
      if (this.isUseDataFromParent && val?.items.length) {
        this.contact_actions = [];
        const mappedContact = val?.items.map(action => ({...action, action_date: this.getDateWithDayNameAndTimeInUserTimeZone(action.action_date)}));
        this.totalItems = val?.totalCount;
        this.contact_actions.push(...mappedContact);
      }
    },
  },
  async created() {
    if (this.isUseDataFromParent === false) {
      await this.get_contact_actions();
    } else {
      const mappedContact = this.parentContactActions.items.map(action => ({...action, action_date: this.getDateWithDayNameAndTimeInUserTimeZone(action.action_date)}));
      this.totalItems = this.parentContactActions.totalCount;
      this.$set(this, 'contact_actions', mappedContact);
    }

    await this.getTactics();
  },
  methods: {
    on_preview_creative(creative) {
      this.creative_dialog_opened = true;
      this.creative_content = creative.creative;
      this.creative = creative;
    },
    onFilterActivity() {
      this.currentPageNumber = 1;
      this.get_contact_actions();
    },
    async get_contact_actions() {
      const data = (await this.$rest.contact.get_contact_actions(this.id, {
            actionType: this.filterActionsType,
            hide_loader: false,
            page: this.currentPageNumber,
            limit: this.currentPageSize,
          })).data;

      this.contact_actions = data.items.map(action => ({...action, action_date: this.getDateWithDayNameAndTimeInUserTimeZone(action.action_date)}));
      this.totalItems = data.totalCount;
    },
    async getTactics() {
      const response = await this.$rest.tactic.get_collection();
      if (!response.data) {
        return;
      }

      this.tactics = response.data.map((item) => ({
        label: item.name,
        value: true,
      }));
    },
    nextPage() {
      this.currentPageNumber++;
      this.get_contact_actions();
    },
    prevPage() {
      this.currentPageNumber--;
      this.get_contact_actions();
    },
    changePageSize(pageSize) {
      this.currentPageSize = pageSize;
      this.currentPageNumber = 1;
      this.get_contact_actions();
    }
  },
};
</script>

<style lang="scss">
.digital-footprint {
  background: white;
  height: 100%;
  .footprint-header {
    font-family: PlayfairDisplay;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.73;
    &__name {
      font-size: 14px;
      line-height: 16px;
      color: #2b84eb;
    }
    &__title {
      font-size: 22px;
      line-height: 16px;
      color: #241c15;
    }
  }
}
.button-block {
  height: 47px;
  border-bottom: solid 1px #dee7ee;
  border-top: solid 1px #dee7ee;
}
</style>
